import { mLog } from "../logger";

export const clientHelper =
  (operationType) => (apolloClient) => async (argObject) => {
    let data = null;

    try {
      data = await apolloClient[operationType](argObject);
    } catch (error) {
      mLog(error);
      return { data, error };
    }

    if (!data || !data.data) {
      return {
        data,
        error: {
          message: "Empty Data",
          networkError: { message: "Empty Data" }
        }
      };
    }

    return { data: data.data, error: null };
  };

export const graphQLQueryHelper = (apolloClient) => async (argObject) => {
  const args = { fetchPolicy: "no-cache", ...argObject }; // Disable cache by default when performing a manual query
  return clientHelper("query")(apolloClient)(args);
};

export const graphQLQueryCacheHelper = (apolloClient) => async (argObject) => {
  const args = {
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-and-network",
    ...argObject
  };
  return clientHelper("query")(apolloClient)(args);
};

/* Please prefer graphQLMutationHelper instead of this function whenever possible */
export const graphQLClientMutationHelper =
  (apolloClient) => async (argObject) => {
    const { data, error } = await clientHelper("mutate")(apolloClient)(
      argObject
    );

    if (error) {
      return { data: {}, error };
    }

    const actualData = Object.values(data)[0];

    return { data: actualData, error: null };
  };

export const graphQLMutationHelper = (mutate) => async (argObject) => {
  let data = null;

  try {
    data = await mutate(argObject);
  } catch (error) {
    mLog(error);
    return { data, error };
  }

  if (!data || !data.data) {
    return { data, error: { networkError: "Empty Data" } };
  }

  const actualData = Object.values(data.data)[0]; // Access for example {data: {userPasswordLogin: {data: []}}}

  return { data: actualData, error: null };
};

export const getGraphQLErrorMessage = (errorObj) => {
  if (errorObj.networkError) {
    return errorObj.networkError.message;
  }
  return errorObj.graphQLErrors[0].message;
};

export const getGraphQLErrorCode = (errorObj) => {
  if (errorObj.networkError) {
    return "NETWORK_REQUEST_FAILED";
  }
  return errorObj.graphQLErrors[0].extensions.code;
};

export const graphQLErrorMessage = (errorObj) => {
  if (errorObj.graphQLErrors && errorObj.graphQLErrors.length > 0) {
    return errorObj.graphQLErrors[0].message;
  }
  return errorObj.networkError.message;
};

export const graphQLErrorCode = (errorObj) => {
  if (errorObj.graphQLErrors && errorObj.graphQLErrors.length > 0) {
    return errorObj.graphQLErrors[0].extensions.code;
  }
  return "NETWORK_REQUEST_FAILED";
};
