import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, Text, Platform } from "react-native";
import { getLineHeight } from "./utils";
import { getColor } from "../common/utils";

const SText = props => {
  const {
    size,
    color,
    weight,
    align,
    transform,
    lineHeight,
    children,
    containerStyle,
    ...restProps
  } = props;

  const styles = createStyles(props);

  return (
    <Text style={[styles.container, containerStyle]} {...restProps}>
      {children}
    </Text>
  );
};

const createStyles = ({ size, color, weight, align, transform, lineHeight }) =>
  StyleSheet.create({
    container: {
      color: getColor(color),
      textTransform: transform,
      textAlign: align,
      fontSize: size,
      fontWeight: weight,
      lineHeight: getLineHeight(size, lineHeight)
    }
  });

SText.defaultProps = {
  size: 14,
  color: "primary",
  weight: "normal",
  align: Platform.OS === "web" ? "inherit" : "auto",
  transform: "none",
  children: <></>,
  containerStyle: {}
};

SText.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  weight: PropTypes.string,
  align: PropTypes.oneOf([
    "inherit",
    "auto",
    "left",
    "center",
    "right",
    "justify"
  ]),
  transform: PropTypes.oneOf(["none", "uppercase", "lowercase", "capitalize"]),
  lineHeight: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  containerStyle: Text.propTypes.style,
  testID: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  ...Text.propTypes
};

export default SText;
