import Colors from "../../../config/colors";

// Name to color mapping for common colors
export const colorMapping = {
  // === Font-colors ==============
  primary: Colors.BlueGrey[12],
  secondary: Colors.BlueGrey[7],
  tertiary: Colors.BlueGrey[5],
  link: Colors.Purple[1],
  error: Colors.Red[7],
  success: Colors.Green[9],
  alert: Colors.Yellow[10],
  // === Primary-colors ===========
  blue: Colors.Blue[9],
  red: Colors.Red[4],
  green: Colors.Green[5],
  yellow: Colors.Yellow[5],
  "light-blue": Colors.LightBlue[5],
  // === Snackbar-colors ==========
  snackbarSuccess: Colors.Green[10],
  snackbarError: Colors.Red[12]
};

// Default affix TouchableHighlight props
export const affixButtonProps = {
  touchableType: "highlight",
  activeOpacity: 0.8,
  underlayColor: Colors.Grey[2]
};

// Default affix SText props
export const affixTextProps = {
  weight: "normal",
  color: Colors.BlueGrey[6]
};

// Default affix disabled SText props
export const disabledAffixTextProps = {
  color: Colors.Grey[5]
};

// Default affix Icon props
export const affixIconProps = {
  fill: Colors.BlueGrey[6]
};

// Default affix disabled Icon props
export const disabledAffixIconProps = {
  fill: Colors.Grey[5]
};
