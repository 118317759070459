import { mLog } from "utils/logger";

import {
  back,
  deepLinkTo,
  getRouter,
  hardPush,
  navigateTo,
  push,
  replace,
  reset,
  stackCount,
  popN
} from "./MyRouter";

function navigate(route, params = {}, type = "push", stack = "CommonStack") {
  console.log("Navigate NavRouter: ", route, params);
  try {
    if (type === "replace") {
      replace(route, stack)(params);
    } else if (type === "hardPush") {
      hardPush(route)(params); // hard refresh for the web!
    } else if (type === "back") {
      back();
    } else if (type === "navigate") {
      navigateTo(route, stack)(params);
    } else if (type === "deepLink") {
      deepLinkTo(route)(params);
    } else if (type === "reset") {
      reset(route)(params);
    } else if (type === "popN") {
      popN(params?.pos);
    } else {
      push(route, stack)(params);
    }
  } catch (error) {
    mLog("============ navigate error", route, params, type, error);
  }
}

export { push, replace, hardPush, back, navigate, getRouter, stackCount };
