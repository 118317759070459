export const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];

export const monthsLowerCase = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
];

export const monthsCamelCase = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const monthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const monthFullNamesVernac = [
  "generic_january",
  "generic_february",
  "generic_march",
  "generic_april",
  "generic_may",
  "generic_june",
  "generic_july",
  "generic_august",
  "generic_september",
  "generic_october",
  "generic_november",
  "generic_december"
];

export const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const daysCamelCase = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const daysFullNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const getMonthNumber = {
  jan: "01",
  feb: "02",
  mar: "03",
  apr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  aug: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dec: "12"
};

export const zeroPad = num => (num < 10 ? `0${num}` : `${num}`);

export const getDateOrdinal = function(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
