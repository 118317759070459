import objectPath from "object-path";

/**
 * This function accesses deep properties of specified data 
 * using a path string.
 * 
 * @param {any} data Data to be Accessed.
 * @param {path} path Path to the property.
 * @param {value} [value] Default value of property if path doesn't exists.
 * 
 * @returns {any} Accessed property of object.
 * */
const getData = objectPath.get;

export default getData;