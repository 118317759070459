import cookie from "js-cookie";

export function getValue(key) {
  return cookie.get(key);
}

export function setValue(key, value, CookieAttributes) {
  cookie.set(key, value, CookieAttributes)
}

export function delValue(key, CookieAttributes) {
  cookie.remove(key, CookieAttributes)
}

export function getValueAsync(key) {
  return cookie.get(key);
}

export function setValueAsync(key, value, CookieAttributes) {
  cookie.set(key, value, CookieAttributes);
}