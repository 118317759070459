import RouterObject, { Router } from "root/routes";
import { mLog } from "../../logger";

export const getRouteInformation = link => {
  const match = RouterObject.match(link);

  return {
    url: match.parsedUrl.path,
    route: match.route ? match.route.name : "Unknown",
    params: match.query
  };
};

class NavHistory {
  constructor() {
    this._init();
  }

  reset = () => {
    this._init();
  };

  _init = () => {
    this.history = [];

    if (process.browser) {
      this.history.push(getRouteInformation(window.location.href)); // Because the router isn't completely ready yet, manually parse
    }
  };

  _replace = url => {
    if (url !== this.history[this.history.length - 1].url) {
      this.pop(); // replace the old url with the new one
      this._push(url);
    }
  };

  _push = url => {
    if (process.browser) {
      this.history.push(getRouteInformation(url)); // Because the router isn't completely ready yet, manually parse
      // mLog("new history", this.history);
    }
  };

  get() {
    return this.history;
  }

  pop() {
    if (process.browser) {
      return this.history.pop();
    }

    return this.history();
  }
}

Router.events.on("routeChangeStart", url => {
  mLog("URL ---", historyInstance.history);
  return historyInstance._push(url);
});

Router.events.on("routeChangeComplete", url => {
  mLog("URL ---", historyInstance.history);
  return historyInstance._replace(url);
});

Router.events.on("routeChangeError", _ => {
  mLog("URL ---", historyInstance.history);
  return historyInstance.pop();
});

const historyInstance = new NavHistory();

export default historyInstance; // make sure it's a singleton
