import { colorMapping } from "../constants";
import Colors from "../../../config/colors";

export const getColor = (color) => colorMapping[color] || color;

export const getColorBasedOnState = (
  { disabled, error, checked },
  defaultColor = Colors.BlueGrey[4]
) => {
  if (disabled) {
    return Colors.BlueGrey[4];
  }
  if (error) {
    return Colors.Red[7];
  }
  if (checked) {
    return Colors.Purple[1];
  }
  return defaultColor;
};
