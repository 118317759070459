/* eslint-disable class-methods-use-this */
import { mLog } from "utils/logger";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

const inMemStorage = {};
function AsyncStorageException(message) {
  this.message = message;
  this.name = "AsyncStorageException";
}

class SingletonAsyncStorage {
  constructor() {
    if (!SingletonAsyncStorage.instance) {
      SingletonAsyncStorage.instance = this;
    }
    return SingletonAsyncStorage.instance;
  }

  async storeDataToStorage(dataKey, dataValue) {
    try {
      if (Platform.OS === "web" && typeof window === "undefined") {
        mLog("[utils/storage] SSR AsyncStorage not available");
        return;
      }
      if (inMemStorage[dataKey] === dataValue) return;

      inMemStorage[dataKey] = dataValue;
      const jsonData = JSON.stringify(dataValue);
      await AsyncStorage.setItem(dataKey, jsonData);
      mLog(
        `[utils/storage] Value stored to Async Storage: ${dataKey} =`,
        dataValue
      );
    } catch (error) {
      delete inMemStorage[dataKey];
      mLog(
        "[utils/storage] Something went wrong while storing data, this might help you: ",
        error
      );
      throw new AsyncStorageException(error);
    }
  }

  async retrieveDataFromStorage(dataKey) {
    let result = null;
    try {
      if (Platform.OS === "web" && typeof window === "undefined") {
        mLog("[utils/storage] SSR AsyncStorage not available");
        return result;
      }

      if (inMemStorage[dataKey]) {
        result = inMemStorage[dataKey];
        // mLog(
        //   `[utils/storage] Value retrieved from InMemory Storage: ${dataKey} =`,
        //   result
        // );
      } else {
        const value = await AsyncStorage.getItem(dataKey);
        if (value !== null) {
          result = JSON.parse(value);
          inMemStorage[dataKey] = result;
          mLog(
            `[utils/storage] Value retrieved from Async Storage: ${dataKey} =`,
            result
          );
        }
      }
    } catch (error) {
      mLog(
        "[utils/storage] Something went wrong while retreiving data, this might help you: ",
        error
      );
      throw new AsyncStorageException(error);
    }
    return result;
  }

  async removeDataFromStorage(dataKey) {
    try {
      if (Platform.OS === "web" && typeof window === "undefined") {
        mLog("[utils/storage] SSR AsyncStorage not available");
        return;
      }
      delete inMemStorage[dataKey];
      await AsyncStorage.removeItem(dataKey);
      mLog(`[utils/storage] Value removed from Async Storage: ${dataKey}`);
    } catch (error) {
      mLog(
        "[utils/storage] Something went wrong while removing data, this might help you: ",
        error
      );
      throw new AsyncStorageException(error);
    }
  }
}

const asyncStorageInst = new SingletonAsyncStorage();
Object.freeze(asyncStorageInst);
export default asyncStorageInst;

export const {
  storeDataToStorage,
  retrieveDataFromStorage,
  removeDataFromStorage
} = asyncStorageInst;

//----- OLD CODES FOR REFERENCE --------

// export const storeDataToStorage = async (dataKey, dataValue) => {
//   try {
//     await AsyncStorage.setItem(dataKey, JSON.stringify(dataValue));
//     mLog(`Value stored to Async Storage: ${dataKey} =`, dataValue);
//   } catch (error) {
//     mLog(
//       "Something went wrong while storing data, this might help you: ",
//       error
//     );
//     throw new AsyncStorageException(error);
//   }
// };

// export const retrieveDataFromStorage = async (dataKey) => {
//   let result = null;
//   try {
//     const value = await AsyncStorage.getItem(dataKey);
//     if (value !== null) {
//       result = JSON.parse(value);
//       mLog(`Value retrieved from Async Storage: ${dataKey} =`, result);
//     }
//   } catch (error) {
//     mLog(
//       "Something went wrong while retreiving data, this might help you: ",
//       error
//     );
//     throw new AsyncStorageException(error);
//   }
//   return result;
// };

// export const removeDataFromStorage = async (dataKey) => {
//   try {
//     await AsyncStorage.removeItem(dataKey);
//     mLog(`Value removed from Async Storage: ${dataKey}`);
//   } catch (error) {
//     mLog(
//       "Something went wrong while removing data, this might help you: ",
//       error
//     );
//     throw new AsyncStorageException(error);
//   }
// };
