/* eslint-disable no-nested-ternary */
import DefaultRouter, { Router as ActualRouter } from "root/routes";
import { getValue } from "utils/LocalKeyValueStorage";
import NavHistory from "../NavHistory";

// JOB-229 SEO issue: Language attribute in URL
// const addLanguageParam = (params) => {
//   const ln = getLanguageShorthand(language);

//   let updatedParams = {};
//   if (!params) {
//     updatedParams = { ln };
//   } else {
//     updatedParams = { ...params, ln };
//   }

//   return updatedParams;
// };

export let stackCount = 0;
export const push = (route) => (params, options) => {
  // const updatedParams = addLanguageParam(params);
  ActualRouter.pushRoute(route, params, options); //updatedParams
};

const routerReplace = (route) => (params) => {
  ActualRouter.replaceRoute(route, params);
};

export const replace = (route) => (params) => {
  // JOB-229 SEO issue: Language attribute in URL
  // const updatedParams = addLanguageParam(params);
  NavHistory.pop();
  routerReplace(route)(params); //updatedParams
};

export const prefetch = (route) => (params) => {
  ActualRouter.prefetchRoute(route, params);
};
export const navigateTo = (route) => (params) => {
  // dummy method jsut to make similarity between MyRouter.js and MyRouter.web.js
};
export const deepLinkTo = (route) => (params) => {
  // dummy method jsut to make similarity between MyRouter.js and MyRouter.web.js
};

export const popN = (n) => {
  // dummy method just to make similarity between MyRouter.js and MyRouter.web.js
};

export const back = () => {
  const history = NavHistory.get();
  let route = "";

  if (history.length > 1) {
    route = history[history.length - 2].url;
  } else {
    const currentPage = history[0];
    const locationHref = window.location.href;

    if (locationHref.indexOf("/candidate-zone/") > 0) {
      route = "dashboard";
    } else if (
      locationHref.indexOf("/sarkari-jobs-results/") > 0 &&
      currentPage.route !== "sarkariJobsHome"
    ) {
      route = "sarkariJobsHome";
    } else {
      route = "home"; // Default back behavior is to go to home!
    }
  }

  // JOB-229 SEO issue: Language attribute in URL
  // const updatedParams = addLanguageParam({});
  NavHistory.pop();
  NavHistory.pop(); // Pop twice to replace the current and previous route!
  routerReplace(route)({}); //updatedParams;
};

export const getRouter = () => ActualRouter;

export const hardPush = (route) => (params) => {
  // JOB-229 SEO issue: Language attribute in URL
  // const updatedParams = addLanguageParam(params);
  const { urls } = DefaultRouter.findAndGetUrls(route, params); //updatedParams

  if (urls) {
    window.location = urls.as; // on web we need to do a hard push to reset apollo state, service worker etc
  } else {
    window.location = route; // this itself is a valid link
  }
};

export const reset = (route) => (params) => {};
