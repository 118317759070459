import {
  retrieveDataFromAsyncStorage,
  storeDataToAsyncStorage
} from "screens/common/utils";
import defaultLanguage from "screens/assets/defaultLanguage.json";
import restart from "./restartUtils";

const LANGUAGE_CONSTANTS = {
  english: "English",
  hindi: "Hindi",
  hinglish: "Hinglish"
};

const LANGUAGE_STORAGE_KEY = "LanguageChanged";
const DATA_STORAGE_KEY = "StoringLanguageJson";

const languageVersion = {
  English: 1,
  Hindi: 1,
  Hinglish: 1
};

let selectedLanguage: any;
let languageData: any;

export const initializeAndLoad = async (appRestart = true ) => {
  selectedLanguage = await retrieveDataFromAsyncStorage(LANGUAGE_STORAGE_KEY);

  languageData = await retrieveDataFromAsyncStorage(DATA_STORAGE_KEY);

  if (!selectedLanguage) {
    if (
      !languageData ||
      languageData?.version !== languageVersion[languageData?.language]
    ) {
      storeDataToAsyncStorage(DATA_STORAGE_KEY, defaultLanguage);
    }
  }

  if (selectedLanguage) {
    const versionNumber = languageVersion[selectedLanguage];
    if (
      selectedLanguage !== languageData?.language ||
      versionNumber !== languageData?.version
    ) {
      const url = `https://s3.ap-southeast-1.amazonaws.com/olxjobs-static.aasaanjobs.net/${selectedLanguage}${versionNumber}.json`;
     
     try{
      const JsonLanguage = await DownloadContent(url);
      await storeDataToAsyncStorage(DATA_STORAGE_KEY, JsonLanguage);
      languageData = JsonLanguage;
      if(appRestart) { restart()};
     }catch(e){
      console.log("error in api of languages ", e)
     }
      
    }
  }
};

const DownloadContent = async (url) => {
  try {
    const fetchResponse = await fetch(url);
    return await fetchResponse.json();
  } catch (err) {
    console.error("Error in fetch lagugage api - ", err);
    await storeDataToAsyncStorage(DATA_STORAGE_KEY, defaultLanguage);
  }
};

export const getLanguageShorthand = (language) => {
  if (language === LANGUAGE_CONSTANTS.english) return "en";
  if (language === LANGUAGE_CONSTANTS.hindi) return "hn";
  if (language === LANGUAGE_CONSTANTS.hinglish) return "he";
  return "df";
};

export const translateStringWithValues = (vernacKey, params) => {
  let finalString = translateString(vernacKey);
  for (let i = 0; i < params.length; i += 1) {
    finalString = finalString.replace("%s", params[i]);
  }
  return finalString;
};

export const translateString = (vernacKey) => {
 // console.log('vernacmanager translating for', languageData);
  let finalString =
    languageData?.keys?.[vernacKey] || defaultLanguage?.keys?.[vernacKey];
  if (!finalString) {
    finalString = "";
  }
  return finalString;
};

export const setSelectedLanguage = async (language: string): Promise<void> => {
  // console.log(`vernacmanager changing language language}`);
  await storeDataToAsyncStorage(LANGUAGE_STORAGE_KEY, language);
  return initializeAndLoad();
};

export const getSelectedLanguage = (): string => selectedLanguage;
