import objectPath from "object-path";
// import { getDayDifference } from "screens/common/JobCard/utils";
import { Platform, Dimensions } from "react-native";
import { mLog } from "utils/logger";
import { monthsCamelCase } from "utils/dates";
import getData from "utils/getData";
import { requestLocationPermissionState } from "utils/location/requestLocationPermission";
import { slugify } from "utils/parseUrl";
import VernacManager from "utils/vernacular";
import { storeDataToStorage, retrieveDataFromStorage } from "utils/storage";

export {
  storeDataToStorage as storeDataToAsyncStorage,
  retrieveDataFromStorage as retrieveDataFromAsyncStorage,
  removeDataFromStorage as removeDataFromAsyncStorage
} from "utils/storage";

export const proficiencyFormatting = (prof) => {
  switch (prof) {
    case "LESS_THAN_10":
      return "Less than 10th";
    case "TENTH":
      return "Tenth";
    case "TWELFTH":
      return "Twelfth";
    case "UNDERGRADUATE":
      return "Undergraduate";
    case "PURSUING_GRADUATION":
      return "Pursuing Graduation";
    case "GRADUATE":
      return "Graduate";
    case "POST_GRADUATE":
      return "Post Graduate";
    case "DOCTORATE":
      return "Doctorate";
    case "DIPLOMA":
      return "Diploma";
    default:
      return null;
  }
};

// Returns location in Locality, City format if possible
export const locationFormatting = (locationIs) => {
  let result = "";

  if (locationIs && locationIs.name) {
    result += locationIs.name;
  }
  if (locationIs && locationIs.city && locationIs.city.name) {
    result += `, ${locationIs.city.name}`;
  }

  return result === "" ? undefined : result;
};

export const validateNull = (realParentObj, childrenArray) => {
  let parentObj = {};
  let newParentObj = {};

  if (parentObj) {
    parentObj = { ...realParentObj };
  }

  childrenArray.forEach((item) => {
    if (item) {
      if (
        Object.prototype.hasOwnProperty.call(parentObj, Object.keys(item)[0])
      ) {
        let newValue = "";
        if (parentObj[Object.keys(item)[0]]) {
          newValue = parentObj[Object.keys(item)[0]];
        } else {
          newValue = Object.values(item)[0];
        }
        newParentObj = {
          ...newParentObj,
          [Object.keys(item)[0]]: newValue
        };
      } else {
        newParentObj = {
          ...newParentObj,
          [Object.keys(item)[0]]: Object.values(item)[0]
        };
      }
    }
  });

  return newParentObj;
};

export const qualificationFormatting = (qualification) => {
  const qualificationList = {
    LESS_THAN_10: "Less than 10th",
    TENTH: "10th",
    TWELFTH: "12th",
    DIPLOMA: "Diploma",
    GRADUATE: "Graduate",
    POST_GRADUATE: "Post Graduate",
    Default: "select"
  };
  if (qualificationList[qualification]) {
    return qualificationList[qualification];
  }
  return qualificationList.Default;
};

export const deepGet = (object) => (path) =>
  objectPath.get(object, path, false);

export const isValidMobile = (str) =>
  str.match(/^(\+91)?[6-9][0-9]{9}$/) !== null;

export const isValidNumber = (str) => toString(str).match(/^[0-9]+$/) !== null;

export const isValidString = (str) => str.match(/^[A-Za-z. ]+$/) !== null;

export const isValidEmail = (str) =>
  str.match(
    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|in|global|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/
  ) !== null;

export const isValidName = (str) => {
  const letters = /^[A-Za-z]+$/;
  return str.match(letters) !== null;
};

export const isValidPassword = (str) => {
  const pattern = /^[A-Za-z\d$@$!%*#?&]{6,}$/;
  return str.match(pattern) !== null;
};

export const isValidOTP = (str) => {
  const digits = /^\d+$/;
  return str.match(digits) !== null;
};

export const formatSalary = (num) =>
  ` ${num}`.replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
    (m, s1, s2) => s2 || `${s1},`
  );

export const indianFormatSalary = (str) => {
  const num = str.toString().replace(/\D/g, "");
  let lastThree = num.substring(num.length - 3);
  const otherNumbers = num.substring(0, num.length - 3);
  if (otherNumbers !== "") lastThree = `,${lastThree}`;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

export const revertSalaryFormat = (num) => num.replace(/,/g, "");

export const validateSalary = (salary, period) => {
  const error = {};
  if (period === "MONTHLY") {
    if (salary < 5000 && salary >= 1000) {
      error.salary = "Min Monthly salary seems too low.";
      error.isHardError = false;
    } else if (salary > 100000 && salary <= 10000000) {
      error.salary = "Max Monthly salary seems too high.";
      error.isHardError = false;
    } else if (salary < 1000) {
      error.salary = "Min Monthly Salary can't be less than Rs 1000.";
      error.isHardError = true;
    } else if (salary > 10000000) {
      error.salary = "Max Monthly Salary can't be more than 1 Cr.";
      error.isHardError = true;
    } else {
      error.salary = null;
    }
  }
  if (period === "ANNUAL") {
    if (salary < 60000 && salary >= 12000) {
      error.salary = "Min Annual salary seems too low.";
      error.isHardError = false;
    } else if (salary > 10000000 && salary <= 120000000) {
      error.salary = "Max Annual salary seems too high.";
      error.isHardError = false;
    } else if (salary < 12000) {
      error.salary = "Min Annual Salary can't be less than Rs 12,000.";
      error.isHardError = true;
    } else if (salary > 120000000) {
      error.salary = "Max Annual Salary can't be more than 12 Cr.";
      error.isHardError = true;
    } else {
      error.salary = null;
    }
  }

  return error;
};

export const timeTo12HrFormat = (time) => {
  let timeIs = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (timeIs.length > 1) {
    timeIs = timeIs.slice(1);
    timeIs[5] = +timeIs[0] < 12 ? " AM" : " PM";
    timeIs[0] = +timeIs[0] % 12 || 12;
  }
  timeIs.splice(3, 1);
  return timeIs.join("");
};

export const monthYear = (fInput) => {
  if (fInput === "MONTHLY") return "month";
  return "year";
};

export const fileNameFromURL = (fileName) => {
  const parts = fileName.split("/");
  return parts[parts.length - 1];
};

export const monthsToYears = (months) => {
  let yearsMonths = "";
  if (months / 12 >= 1) {
    if (months / 12 === 1) {
      yearsMonths = "1 year";
    } else {
      yearsMonths = Math.floor(months / 12);
      yearsMonths = `${yearsMonths.toString()} years${
        months % 12 ? ` ${getMonth(months)}` : ""
      }`;
    }
  } else {
    yearsMonths = getMonth(months);
  }
  return yearsMonths;
};

export const getMonth = (months) => {
  let monthRemaining = "";
  if (months % 12 === 1) {
    monthRemaining = "1 month";
  } else {
    monthRemaining = months ? `${(months % 12).toString()} months` : "0";
  }
  return monthRemaining;
};

export const getMonthName = (num) => monthsCamelCase[num];

export const getDoubleDigitMonth = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
};

export const getDateFormatted = (date, option = true) => {
  const splitDates = date.split("-");
  let month = parseInt(splitDates[1], 10);
  month = monthsCamelCase[month - 1];
  const year = parseInt(splitDates[0], 10);
  if (option) return `${month}, ${year}`;
  return `${month} ${year}`;
};

export const getDegreeCorrect = (degree, proficiencyLevel) => {
  if (!degree) {
    if (proficiencyLevel === "DIPLOMA") return "Diploma";
    if (proficiencyLevel === "TWELFTH") return "12th";
    if (proficiencyLevel === "TENTH") return "10th";
    if (proficiencyLevel === "LESS_THAN_10") return "Less than 10th";
    if (proficiencyLevel === "UNDERGRADUATE") return "Undergraduate";
    if (proficiencyLevel === "PURSUING_GRADUATION")
      return "Pursuing Graudation";
    if (proficiencyLevel === "GRADUATE") return "Graduate";
    if (proficiencyLevel === "POST_GRADUATE") return "Post Graduate";
  } else {
    return degree;
  }
};

export const dateDifference = (startedFrom, workedTill) => {
  const sDate = new Date(startedFrom);
  const tDate = new Date(workedTill);

  let diffMonths =
    (parseInt(tDate.getFullYear(), 10) - parseInt(sDate.getFullYear(), 10)) *
    12;
  diffMonths += parseInt(tDate.getMonth(), 10) - parseInt(sDate.getMonth(), 10);

  if (diffMonths > 0) {
    return true;
  }
  return false;
};

export const genderFormatting = (gender) => {
  if (gender === "MALE") return "Male";
  if (gender === "FEMALE") return "Female";
  return "N/A";
};

export const languageModesFormatting = (langMode) =>
  langMode.charAt(0) + langMode.slice(1).toLowerCase();

export const punctuationFormatting = (loc, array) => {
  let locLen = array.length - array.indexOf(loc);
  if (locLen === 1) return "";
  locLen -= 1;
  return ", ";
};

export const noticeFormatting = (unformattedPeriod) => {
  const noticeIndex = {
    IMMEDIATELY: "generic_immediately",
    SEVEN_DAYS: "generic_seven_days",
    FIFTEEN_DAYS: "generic_fifteen_days",
    ONE_MONTH: "generic_one_month",
    TWO_MONTHS: "generic_two_month",
    THREE_MONTHS_OR_MORE: "generic_three_or_more_months"
  };
  const vernacKey = noticeIndex[unformattedPeriod];
  const finalString = VernacManager.translateString(vernacKey);
  return finalString;
};

export const getCity = (arrayIs) => {
  try {
    const cityArray = arrayIs.filter(
      (item) => item.types[0] === "locality" || item.types[0] === "country"
    );
    return Platform.OS === "web" ? cityArray[0].long_name : cityArray[0].name;
  } catch (e) {
    return null;
  }
};

export const arrayToObject = (array, keyField, valueField) => {
  const finalObj = array.reduce((obj, item) => {
    obj[item[keyField]] = valueField ? item[valueField] : item;
    return obj;
  }, {});
  return finalObj;
};

export const stringFormattingForSEO = (inputString, isHTML) => {
  const stripedHtml = inputString.replace(/"/g, "'");
  return isHTML
    ? stripedHtml.replace(/\\(?=(((?!>).)*<)|[^<>]*$)/g, "/")
    : stripedHtml.replace(/\//g, "/");
};

export const getRelativeWidth = (truncatedWidth, viewPortFactor = 1) => {
  const finalWidth =
    (Dimensions.get("window").width / viewPortFactor) * 0.6 - truncatedWidth;

  let searchBoxWidth = "auto";

  if (Dimensions.get("window").width > 800) {
    // console.log("finalWidth", finalWidth);
    searchBoxWidth = finalWidth;
  } else if (Platform.OS === "web") {
    searchBoxWidth = `calc(${100 / viewPortFactor}vw - ${truncatedWidth}px)`;
  } else {
    searchBoxWidth =
      Dimensions.get("window").width / viewPortFactor - truncatedWidth;
  }
  // console.log("searchBoxWidth", searchBoxWidth);
  return searchBoxWidth;
};

// See: https://stackoverflow.com/questions/770523/escaping-strings-in-javascript
export const escapeString = (inputString) =>
  `${inputString}`.replace(/[\\"]/g, "\\$&");

export const array_move = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing purposes
};

export const getViewPortHeight = (value = 100) => {
  const { height } = Dimensions.get("window");
  return (height / 100) * value;
};

export const getViewPortWidth = (value = 100) => {
  const { width } = Dimensions.get("window");
  return (width / 100) * value;
};

export const isPaidJob = (pricingPlanType) => {
  return ["PREPAID", "POSTPAID", "TEMP_STAFFING"].includes(pricingPlanType);
};

export const getLocationClevertapProps = async (location = "", user = {}) => {
  const result = {
    Location_type: "External Link",
    Location_Access: "Not Allowed",
    IP_Location: "NA",
    User_entered_location: "NA",
    Browser_detected_location: "NA",
    Profile_Location: "NA"
  };

  let locationModes = {};

  const locality = getData(user, "stakeholder.address.locality.name");

  const permission = await requestLocationPermissionState();

  const stringifiedData = await retrieveDataFromStorage("locationEntryMode");

  if (stringifiedData) {
    locationModes = JSON.parse(stringifiedData) || {};
  }

  if (locationModes.ip_detected && locationModes.ip_detected.name) {
    result.IP_Location = locationModes.ip_detected.name;
  }
  if (
    locationModes.user_entered_location &&
    locationModes.user_entered_location.name
  ) {
    result.User_entered_location = locationModes.user_entered_location.name;
  }
  if (
    permission === "granted" &&
    locationModes.current_location &&
    locationModes.current_location.name
  ) {
    result.Browser_detected_location = locationModes.current_location.name;
  }
  if (locality) {
    result.Profile_Location = locality;
  }

  if (permission === "granted") {
    result.Location_Access = "Allowed";
  } else if (permission === "prompt") {
    result.Location_Access = "Ask";
  }

  if (location) {
    const searchItem = slugify(location);

    const IPLocation = slugify(result.IP_Location);
    const UELocation = slugify(result.User_entered_location);
    const BDLocation = slugify(result.Browser_detected_location);
    const ProfileLocation = slugify(result.Profile_Location);

    if (IPLocation && IPLocation.indexOf(searchItem) > -1) {
      result.Location_type = "IP detected";
    } else if (BDLocation && BDLocation.indexOf(searchItem) > -1) {
      result.Location_type = "Browser detected";
    } else if (UELocation && UELocation.indexOf(searchItem) > -1) {
      result.Location_type = "User Entered";
    } else if (ProfileLocation && ProfileLocation.indexOf(searchItem) > -1) {
      result.Location_type = "Profile";
    }
  }

  storeDataToStorage("locationType", result.Location_type);

  return result;
};

export const getImageType = (imageURL) => {
  let imageType = "svg";
  if (imageURL) {
    imageType = imageURL.split("?")[0].substr(imageURL.lastIndexOf(".") + 1);
  }
  return imageType;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
