/* eslint-disable no-console */
import Platform from "utils/platform";
import config from "root/config";

const myLogger = (type) => (...args) => {
  if (Platform.OS === "web") {
    if (typeof window !== "undefined") {
      console[type](...args);
    }
  } else {
    console.log("Android logs", ...args);
  }
};

export const mLog = (...args) => {
  myLogger("log")(...args);
};

export const mWarn = (...args) => {
  myLogger("warn")(...args);
};

export class Logger {
  static log = (log, tag, msg) => {
    const showLog =
      config.ENABLE_ALL_LOGS === "true" ||
      (config.ENABLE_FILE_LOGS === "true" && log);
    if (showLog) {
      console.log(`${tag  } : ${  msg}`);
    }
  };

  static info = (log, tag, msg) => {
    const showLog =
      config.ENABLE_ALL_LOGS === "true" ||
      (config.ENABLE_FILE_LOGS === "true" && log);
    if (showLog) {
      console.info(`${tag  } : ${  msg}`);
    }
  };
}
