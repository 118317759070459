import {
  initializeAndLoad,
  setSelectedLanguage,
  getSelectedLanguage,
  translateString,
  translateStringWithValues
} from "./utils";

class VernacManager {
  static initialize = async (flag = true): Promise<void> => {
    await initializeAndLoad(flag);
  };

  static translateString = (givenString: string): string => {
    return translateString(givenString);
  };

  static setSelectedLanguage = async (language: string): Promise<void> => {
    return setSelectedLanguage(language);
  };

  static translateStringWithValues = (key, params): string => {
    return translateStringWithValues(key, params);
  };

  static getSelectedLanguage = (): string => {
    return getSelectedLanguage();
  };
}

export default VernacManager;
