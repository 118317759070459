import cookie from "js-cookie";

/* Taken from: https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js */

export const setCookie = (
  key,
  value,
  res = undefined,
  expiresOn = { expires: 7 },
  options = {}
) => {
  const numberOfDays = parseInt(expiresOn.expires, 10);
  const expires = new Date(Date.now() + 1000 * 86400 * numberOfDays); // Default expiry after 7 days

  if (Array.isArray(value)) {
    value = value[0];
  }

  if (process.browser) {
    cookie.set(key, value, {
      expires,
      path: "/",
      ...options
    });
  } else if (res) {
    res.cookie(key, value, { expires, ...options, encode: String }); // prevent special chars like = from being encoded
  }
};

export const removeCookie = (key, res = undefined, options = {}) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
      ...options
    });
  } else if (res) {
    res.clearCookie(key, options);
  }
};

export const getCookie = (key, req) =>
  process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req);

const getCookieFromBrowser = key => cookie.get(key);

const getCookieFromServer = (key, req) => {
  if (req) {
    if (!req.headers.cookie) {
      return undefined;
    }
    const rawCookie = req.headers.cookie
      .split(";")
      .find(c => c.trim().startsWith(`${key}=`));
    if (!rawCookie) {
      return undefined;
    }
    return rawCookie.split("=")[1];
  }
  return undefined;
};
