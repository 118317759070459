import { Platform } from "react-native";
import { mLog } from "../logger";

/**
 * This function parses the url and returns the search keyword array
 * and location from the slug field.
 *
 * Cases:
 * 1. /jobs                           All jobs page
 * 2. /jobs-in-{location}             jobs in a location
 * 3. /{keyword}-jobs                 jobs for search keywords
 * 4. /{keyword}-jobs-in-{location}   jobs for search keywords in a location
 * 5. /                               empty page
 */
export const parseUrl = (pathname, slug, localityHasCity) => {
  let keywordArray = [];
  let location;
  let searchKeywords;
  let locationKeyword, locationKeywords;
  if (slug) {
    if (slug.indexOf("-jobs-in-") >= 0) {
      [searchKeywords, locationKeyword] = slug.split("-jobs-in-");
    } else if (slug.indexOf("-jobs") >= 0) {
      [searchKeywords] = slug.split("-jobs");
    } else if (slug.indexOf("jobs-in-") >= 0) {
      [searchKeywords, locationKeyword] = slug.split("jobs-in-");
    } else {
      mLog("slug edge case", slug);
    }
    if (searchKeywords) {
      keywordArray = searchKeywords.split("-").join(" ").split(" and ");
    }
    if (locationKeyword) {
      locationKeywords = locationKeyword.split("-");
      if (localityHasCity) {
        let city = locationKeywords.pop();
        location = locationKeywords.join(" ") + ", " + city;
      } else {
        location = locationKeywords.join(" ");
      }
    }
  }
  return { keywordArray, location };
};

export const parseJobListUrl = (query) => {
  if (query) {
    const { slug, localityHasCity } = query;
    const { keywordArray: keywords, location } = parseUrl(
      "",
      slug,
      localityHasCity
    );
    const { filterMap } = extractFilterFromSearch(query);
    return {
      keywords,
      location,
      appliedFilters: filterMap
    };
  }
};

export const extractFilterFromSearch = (query) => {
  const queryKeys = query ? Object.keys(query) : [];
  const filterMap = new Map();

  for (let i = 0; i < queryKeys.length; i += 1) {
    const filterName = queryKeys[i];
    const filterValue = query[filterName];

    if (filterName !== "slug" && filterName !== "prefix") {
      const newFilterValue =
        Platform.OS === "android"
          ? filterValue instanceof Array
            ? filterValue.length > 0
              ? getFilterValue(filterValue[0])
              : []
            : getFilterValue(filterValue)
          : filterValue instanceof Array
            ? filterValue
            : getFilterValue(filterValue);
      filterMap.set(filterName, newFilterValue);
    }
  }

  console.log("=========filter map", filterMap);

  return { filterMap };
};

/**
 * Return the available filter values
 * @method getFilterValue
 * @param {String} filterValue
 */
const getFilterValue = (filterValue) => {
  return typeof filterValue === "string" && filterValue.indexOf("|") > 0
    ? filterValue.split("|")
    : [filterValue];
};

export const extractFilterFromQueryParams = (queryParamString) => {
  let filterObject = {};

  if (!queryParamString) {
    return { filterObject };
  }

  const filterKeyValuePairArray = queryParamString.split("&");
  filterKeyValuePairArray.forEach((pair) => {
    if (pair && pair.indexOf("=") > -1) {
      const filterKeyValuePair = pair.split("=");
      const filterKey = filterKeyValuePair[0];
      const filterValue = filterKeyValuePair[1];
      if (!filterObject[filterKey]) {
        filterObject[filterKey] = [];
      }
      if (
        filterValue &&
        filterValue instanceof String &&
        filterValue.indexOf("/") > -1
      ) {
        const splitValue = filterValue.split("/");
        filterObject[filterKey] = [...filterObject[filterKey], ...splitValue];
      } else {
        filterObject[filterKey].push(filterValue);
      }
    }
  });

  return { filterObject };
};

export const slugify = (text) => {
  let result = text;
  if (text) {
    result = text
      .toString()
      .toLowerCase()
      .replace(/[\s\/\_]+/g, "-") // Replace spaces, "/", "\" and "_" with "-"
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  return result;
};

export const slugifyParams = (params = {}) => {
  let result = params;
  if (result) {
    if ("slug" in params) {
      result.slug = slugify(result.slug);
    }
  }
  return result;
};

export const extractQueryParamsFromObject = (queryObject) => {
  const queryArray = [];

  if (queryObject) {
    for (let i = 0; i < Object.keys(queryObject).length; i += 1) {
      const filterName = Object.keys(queryObject)[i];
      if (filterName !== "slug") {
        const filterValue = queryObject[filterName];
        if (filterValue instanceof Array) {
          for (let j = 0; j < filterValue.length; j += 1) {
            const filterSubValue = filterValue[j];
            queryArray.push(`${filterName}=${filterSubValue}`);
          }
        } else {
          queryArray.push(`${filterName}=${filterValue}`);
        }
      }
    }
  }

  const queryParamsString = queryArray.join("&");

  return queryParamsString;
};
