import { PermissionsAndroid, Platform } from "react-native";
import { mLog, mWarn } from "utils/logger";

export default async function requestLocationPermission() {
  if (Platform.OS === "web") {
    return requestWebLocationPermission();
  }
  return requestMobileLocationPermission();
}

async function requestWebLocationPermission() {
  if (navigator && navigator.permissions) {
    try {
      // Not using await here so that permission is prompted via pop-up
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          mLog("Web permissions result :", permissionStatus.state);
          // eslint-disable-next-line no-param-reassign
          permissionStatus.onchange = () => {
            mLog("Web permissions onchange result :", permissionStatus.state);
            return permissionStatus.state === "granted";
          };
        });
    } catch (error) {
      mLog("Web permissions error :", error);
      return false;
    }
  }

  // Return true in order to trigger prompt in all cases
  return true;
}

async function requestMobileLocationPermission() {
  try {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
    );
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      mLog("Location permission allowed");
      return true;
    }
    mLog("Location permission denied");
    return false;

  } catch (err) {
    mWarn(err);
    return false;
  }
}

export const requestLocationPermissionState = async () => {
  if (Platform.OS === "web") {
    return requestWebLocationPermissionState();
  }
  return requestMobileLocationPermissionState();
}

async function requestWebLocationPermissionState() {
  if (typeof window !== "undefined" && navigator && navigator.permissions) {
    try {
      const { state } = await navigator.permissions.query({ name: "geolocation" });
      return state;
    } catch (error) {
      mLog("Web permissions error :", error);
      return "denied";
    }
  }
  return "denied";
}

async function requestMobileLocationPermissionState() {
  try {
    const state = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
    return state;
  } catch (error) {
    mLog("Mobile permissions error :", error);
    return "denied";
  }
}