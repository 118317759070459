// Font-size to line-height mapping
export const lineHeights = {
  10: 12,
  12: 16,
  14: 20,
  16: 20,
  18: 24,
  20: 28,
  22: 28,
  24: 32,
  34: 48,
  48: 64
};
